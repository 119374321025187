import { arrow, autoPlacement, autoUpdate, flip, offset } from '@floating-ui/dom'
import { useFloating } from 'solid-floating-ui'
import { children, createEffect, createSignal, JSX, onCleanup, ParentComponent, Show } from 'solid-js'
import { Portal } from 'solid-js/web'

interface ErrorTooltipProps {
	/**
	 * Text to be rendered inside the tooltip
	 */
	text?: string
	/**
	 * Component to be rendered inside the tooltip, this will override the text prop
	 */
	component?: JSX.Element
	/**
	 * Delay in milliseconds before the tooltip is shown
	 */
	delay?: number

	/**
	 * Whether to show an arrow pointing to the element
	 */
	arrow?: boolean

	/**
	 * Whether to use click instead of hover to show the tooltip
	 */
	click?: boolean

	/**
	 * Custom classes for the tooltip bubble
	 */
	customClassesBubble?: string

	/**
	 * Custom classes for the arrow
	 */
	customClassesArrow?: string

	placement?: string
	error?: string
}

const ErrorTooltip: ParentComponent<ErrorTooltipProps> = props => {
	const childRef = children(() => props.children)

	const [tooltip, setTooltip] = createSignal<HTMLElement>()

	let position = useFloating(() => childRef() as HTMLElement, tooltip, {
		whileElementsMounted: autoUpdate,
		placement: 'bottom',
		middleware: [offset(10), flip()],
	})

	return (
		<>
			<Show when={props.error}>
				<Portal>
					<div
						role="tooltip"
						style={{
							position: position?.strategy ?? 'absolute',
							top: `${position?.y ?? 0}px`,
							left: `${position?.x ?? 0}px`,
						}}
						ref={setTooltip}
						class={'tooltip-bubble error-tooltip-bubble ' + props.customClassesBubble}>
						<Show when={props.arrow}>
							<div class={'arrow ' + props.customClassesArrow} />
						</Show>
						<div class="content">
							{props.text}
							{props.component}
						</div>
					</div>
				</Portal>
			</Show>
			{childRef()}
		</>
	)
}

export default ErrorTooltip
